// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-artists-alexandra-leykauf-js": () => import("./../../../src/pages/artists/alexandra-leykauf.js" /* webpackChunkName: "component---src-pages-artists-alexandra-leykauf-js" */),
  "component---src-pages-artists-christin-kaiser-js": () => import("./../../../src/pages/artists/christin-kaiser.js" /* webpackChunkName: "component---src-pages-artists-christin-kaiser-js" */),
  "component---src-pages-artists-eric-meier-js": () => import("./../../../src/pages/artists/eric-meier.js" /* webpackChunkName: "component---src-pages-artists-eric-meier-js" */),
  "component---src-pages-artists-eva-funk-js": () => import("./../../../src/pages/artists/eva-funk.js" /* webpackChunkName: "component---src-pages-artists-eva-funk-js" */),
  "component---src-pages-artists-heike-kabisch-js": () => import("./../../../src/pages/artists/heike-kabisch.js" /* webpackChunkName: "component---src-pages-artists-heike-kabisch-js" */),
  "component---src-pages-artists-heike-kabisch-und-katharina-kiebacher-js": () => import("./../../../src/pages/artists/heike-kabisch-und-katharina-kiebacher.js" /* webpackChunkName: "component---src-pages-artists-heike-kabisch-und-katharina-kiebacher-js" */),
  "component---src-pages-artists-helin-ulas-js": () => import("./../../../src/pages/artists/helin-ulas.js" /* webpackChunkName: "component---src-pages-artists-helin-ulas-js" */),
  "component---src-pages-artists-isaac-chong-js": () => import("./../../../src/pages/artists/isaac-chong.js" /* webpackChunkName: "component---src-pages-artists-isaac-chong-js" */),
  "component---src-pages-artists-jandk-js": () => import("./../../../src/pages/artists/jandk.js" /* webpackChunkName: "component---src-pages-artists-jandk-js" */),
  "component---src-pages-artists-julius-c-schreiner-js": () => import("./../../../src/pages/artists/julius-c-schreiner.js" /* webpackChunkName: "component---src-pages-artists-julius-c-schreiner-js" */),
  "component---src-pages-artists-katharina-luedicke-js": () => import("./../../../src/pages/artists/katharina-luedicke.js" /* webpackChunkName: "component---src-pages-artists-katharina-luedicke-js" */),
  "component---src-pages-artists-roseline-rannoch-js": () => import("./../../../src/pages/artists/roseline-rannoch.js" /* webpackChunkName: "component---src-pages-artists-roseline-rannoch-js" */),
  "component---src-pages-artists-sonya-schoenberger-und-nele-guentheroth-js": () => import("./../../../src/pages/artists/sonya-schoenberger-und-nele-guentheroth.js" /* webpackChunkName: "component---src-pages-artists-sonya-schoenberger-und-nele-guentheroth-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-old-isaac-wai-chong-js": () => import("./../../../src/pages/old/isaac-wai-chong.js" /* webpackChunkName: "component---src-pages-old-isaac-wai-chong-js" */),
  "component---src-pages-old-ruohan-wang-js": () => import("./../../../src/pages/old/ruohan-wang.js" /* webpackChunkName: "component---src-pages-old-ruohan-wang-js" */)
}

